






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { appendSitePrefix, isCurrentSiteId } from '@/utils/routeUtils'
import BookingDialog from '@/components/booking/BookingDialog.vue'
import Booking from '@/models/booking/Booking'
import { vxm } from '@/store'
import DropInBooking from '@/components/booking/DropInBooking.vue'
import TyreHotel from '@/models/tyrehotel/TyreHotel'

@Component({
  components: { DropInBooking, BookingDialog },
})
export default class BookingData extends Vue {
  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  private bookingDialog = false
  private dropInDialog = false
  private bookingLoading = false
  private booking: Booking = null

  private created() {
    this.onBookingIdChanged()
  }

  private get bookingId() {
    return this.tyreHotel.lastTyreHotelWheelChange?.order?.bookingId
  }

  private get orderId() {
    return this.tyreHotel.lastTyreHotelWheelChange?.order?.id
  }

  private get isReadOnly() {
    return this.tyreHotel.isReadOnly
  }

  private get wheelChangeId() {
    return this.tyreHotel.lastTyreHotelWheelChange?.id
  }

  // todo: instead of propagating this and reloading, we could let
  // booking dialog(s) inform us what changed and update data in here directly
  private onComponentChanged(b) {
    this.$emit('onComponentChanged')
  }

  @Watch('bookingId')
  private onBookingIdChanged() {
    if (this.bookingId) {
      this.$axios
        .get('/v4/site/calendars/any/bookings/' + this.bookingId)
        .then((response) => {
          this.booking = new Booking(response.data.data)
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
        .finally(() => {
          this.bookingLoading = false
        })
    }
  }

  private bookAppointment() {
    const backTo = document.location.pathname
    this.$router.push(
      appendSitePrefix('/bookings/new/wheel-change/' + this.wheelChangeId + '?back=' + encodeURIComponent(backTo)),
    )
  }

  private openBooking() {
    if (isCurrentSiteId(this.tyreHotel.lastTyreHotelWheelChange?.siteId)) {
      this.bookingDialog = true
    } else {
      this.$router.push(
        '/' +
          this.tyreHotel.lastTyreHotelWheelChange?.siteId +
          '/bookings/' +
          this.tyreHotel.lastTyreHotelWheelChange?.order?.bookingId,
      )
    }
  }
}
